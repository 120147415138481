import Link from 'next/link';

import { NoFishErrorImage } from '@/components/ErrorsImage/images';

import styles from './index.module.scss';

export default function Custom404() {
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<h1 className={styles.title}>Тут рыбы нет :(</h1>
				<div className={styles.mainDescription}>
					Страница больше не существует, или её адрес был набран неправильно.
				</div>
				<div className={styles.secondaryDescription}>
					Попробуйте начать сначала или поискать что-то другое.
				</div>
				<Link className={styles.linkButton} href="/">
					На главную
				</Link>
			</div>
			<NoFishErrorImage />
		</div>
	);
}
